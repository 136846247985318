import React from 'react'
import styled from 'styled-components'
import logo from '../../images/novo_logo.png'
import MediaQuery from 'react-responsive'

export default function Menu() {

    return (
        <Container>
            <Logo src={logo} alt="@log telecom"/>
            <MediaQuery maxDeviceWidth={1224}>
                <Button mobile href="https://wa.me/5554996978152" target="_blank">(54)99697-8152</Button>
            </MediaQuery>
            <MediaQuery minDeviceWidth={1225}>
                <MenuTop>
                    <MenuItem href="#home">HOME</MenuItem>
                    <MenuItem href="#servicos">SERVIÇOS</MenuItem>
                    <MenuItem href="#planos">PLANOS</MenuItem>
                    <MenuItem href="#contato">CONTATO</MenuItem>

                    <Button href="https://wa.me/555435321471" target="_blank">WhatsApp (54) 3532-1471</Button>

                </MenuTop>
            </MediaQuery>
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 16px 128px;
 
  @media (max-width: 1224px){
    padding: 16px 16px;
  }
 
`

const Logo = styled.img`
  width: 140px;
`

const MenuTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  
  @media (max-width: 1224px){
    display: none;
    text-align: center;
  }
  
`

const MenuItem = styled.a`
  color: #FFFFFF;
  padding: 0 16px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 800;
  
  :hover{
    font-weight: 500;   
  }
`

const Button = styled.a`
  color: ${props => props.mobile ? '#555' : '#FFF'};
  background: transparent;
  border: 2px solid ${props => props.mobile ? '#555' : '#FFF'};
  padding: ${props => props.mobile ? '5px 12px' : '8px 16px'};
  margin-left: 16px;
  border-radius: 24px;
  font-weight: 700;
  cursor: pointer;
  font-size: ${props => props.mobile ? '14px' : '16px'};
  text-decoration: none;
  
  :hover{
    box-shadow: 0 2px 14px rgba(0,0,0,0.1);
  }
  
  
`
