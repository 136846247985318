import React from 'react'
import FiberIcon from './Fiber'
import Team from './Team'
import Facebook from './Facebook'
import Instagram from './Instagram'

export default function Icons({icon, center}) {
  switch (icon) {
    case 'fiber':
      return <FiberIcon center={center}/>
    case 'team':
      return <Team center={center}/>
    case 'facebook':
      return <Facebook center={center}/>
    case 'instagram':
      return <Instagram center={center}/>
    default :
      return <FiberIcon center={center}/>
  }
}
