import React from 'react'
import styled from 'styled-components'
import Icons from '../Icons'

export default function ServiceItem({icon, title, description}) {

  return (
    <Container>
      <Icon>
        <Icons icon={icon}/>
      </Icon>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  )
}

const Container = styled.div`
  background: #FFF;
  padding: 24px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.1);
  width: calc((100% - 256px) / 4);
  text-align: center;
  margin-right: 16px;
  min-height: 230px;
  
  :last-child {
    margin-right: 0;  
  }
  
  :hover{
    box-shadow: 0 2px 14px rgba(0,0,0,0.1);
  }
  
  @media (max-width: 1224px) {
    width: calc(100% - 32px);
    padding: 0;
    margin: 0 0 16px;
  }
  
`


const Icon = styled.div`
  height: 70px;
  min-height: 70px;
  width: 70px;
  min-width: 70px;
  background: #e1a83e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #FFFFFF;
    height: 38px;
    width: 38px;
  }
`

const Title = styled.span`
  display: block;
  color: #0c0c06;
  font-weight: 600;
  font-size: 18px;
  padding: 24px 0 16px 0;
 
 
`

const Description = styled.span`
  display: block;
  color: #929292;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1224px) {
    padding: 0 24px;
  }
  
`
