import React from 'react'

export default function FiberIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Glyph" viewBox="0 0 64 64" width="48" height="48">
      <path
        d="M37,22H57a5,5,0,0,0,0-10H31.414L23.707,4.293A1,1,0,0,0,23,4H16V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V4H2V6H6V7A1,1,0,0,0,7,8h8a1,1,0,0,0,1-1V6h6.586l6,6H16V11a1,1,0,0,0-1-1H7a1,1,0,0,0-1,1v1H2v2H6v1a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V14H28.586l-6,6H16V19a1,1,0,0,0-1-1H7a1,1,0,0,0-1,1v1H2v2H6v1a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V22h7a1,1,0,0,0,.707-.293L31.414,14H57a3,3,0,0,1,0,6H37a5,5,0,0,0,0,10H57a3,3,0,0,1,0,6H37a5,5,0,0,0,0,10H54.5a5.5,5.5,0,0,1,0,11H23V56a1,1,0,0,0-1-1H19a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1H6a1,1,0,0,0-1,1v1H2v2H5v1a1,1,0,0,0,1,1H9a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1h3a1,1,0,0,0,1-1V59H54.5a7.5,7.5,0,0,0,0-15H37a3,3,0,0,1,0-6H57a5,5,0,0,0,0-10H37a3,3,0,0,1,0-6ZM7,59V57H9v2Zm14,0H19V57h2Z"/>
    </svg>
  )
}
