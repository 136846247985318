import React from 'react'
import Home from './pages/home'

function App() {

  return (
    <>
      <Home/>
    </>
  )
}

export default App
