import React from 'react';
import styled from 'styled-components';
import Icons from '../Icons';
// import {useModal} from "react-modal-hook";
// import ReactModal from "react-modal";
//
// const customStyles = {
//     content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)',
//         padding: "16px 32px"
//     }
// };

export default function Plans() {

  // const [showModal, hideModal] = useModal(() => (
  //     <ReactModal isOpen style={customStyles} onRequestClose={hideModal}>
  //         <ModalTitle>Preencha seus dados que entraremos em contato!</ModalTitle>
  //
  //         <ModalForm>
  //             <Input type="text"/>
  //
  //
  //         </ModalForm>
  //
  //     </ReactModal>
  // ));

  return (
    <Content>
      <TitleSection>CONHEÇA NOSSOS PLANOS</TitleSection>
      <Description>Conheça alguns de nossos planos de internet via radio e fibra
        óptica</Description>
      <Container id="planos">

        <Box>
          <Icon>
            <Icons icon=""/>
          </Icon>
          <Title>BÁSICO</Title>
          <Speed>10MB</Speed>
          <Label>Ideal para sua casa</Label>
          <Label>Melhor custo/benefício</Label>
          {/*<Button onClick={showModal}>QUERO ESSE PLANO</Button>*/}
        </Box>

        <Box center>
          <Star center>MAIS VENDIDO</Star>
          <Icon center>
            <Icons icon="" center={true}/>
          </Icon>
          <Title center>PROFISSIONAL</Title>
          <Speed center>100MB</Speed>
          <Label center>Melhor velocidade</Label>
          <Label center>Ideal para jogos e videos online</Label>
          <Label center>Assista tudo em 4k</Label>
          {/*<Button onClick={showModal}>QUERO ESSE PLANO</Button>*/}
        </Box>

        <Box>
          <Icon>
            <Icons icon=""/>
          </Icon>
          <Title>PRO</Title>
          <Speed>40MB</Speed>
          <Label>Melhor velocidade</Label>
          <Label>Ideal para jogos e videos online</Label>
          {/*<Button onClick={showModal}>QUERO ESSE PLANO</Button>*/}
        </Box>

      </Container>
    </Content>
  );
}

const Content = styled.div`
  padding-top: 16px;
  background: #F5FCFD;
  text-align: center;
  width: 100%;

  @media (max-width: 1224px) {
    padding-top: 24px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px 128px;
  background: #F5FCFD;

  @media (max-width: 1224px) {

    flex-direction: column;
    padding: 0 16px;
  }
`;


const TitleSection = styled.span`
  display: block;
  color: #0c0c06;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 8px;


`;
const Description = styled.span`
  display: block;
  color: #929292;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 48px;
  line-height: 24px;

`;

const Star = styled.span`
  position: absolute;
  top: -8px;
  background: red;
  border-radius: 16px;
  padding: 1px 16px;
  font-size: 14px;
  font-weight: 700;
  color: #FFF;

`;

const Box = styled.div`
  position: relative;
  //background: #FFF;
  background: ${props => props.center ? '#9c5c0c' : '#FFF'};
  border-radius: 5px;
  padding: 40px 16px;
  min-height: 100%;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc((100% - 500px) / 3);
  margin: ${props => props.center ? '0 16px' : '0'};

  :hover {
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
    transition: all ease 0.4s;
  }

  @media (max-width: 1224px) {
    width: calc(100% - 32px);
    margin-bottom: 24px;
  }

`;

const Icon = styled.div`
  height: 100px;
  width: 100px;
  background: ${props => props.center ? '#fff' : '#e1a83e'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${props => props.center ? '#e1a83e' : '#fff'};
    height: 55px;
    width: 55px;
  }
`;

const Title = styled.div`
  font-weight: ${props => props.center ? '800' : '400'};;
  color: ${props => props.center ? '#fff' : '#0c0c06'};
  font-size: 24px;
  padding: 24px 0;
`;

const Speed = styled.div`
  font-weight: 700;
  color: ${props => props.center ? '#fff' : '#0c0c06'};;
  font-size: 32px;
  padding-bottom: 24px;
`;

const Label = styled.div`
  display: block;
  color: ${props => props.center ? '#fff' : '#0c0c06'};;
  font-size: 16px;
  padding-bottom: 8px;
  font-weight: 400;
`;
