import React from 'react';
import styled from 'styled-components';
import background from '../../images/background-top.webp';
import Menu from '../header';
import HeaderSlider from '../headerSlider';
import Service from '../service';
import Plans from '../plans';
import Contact from '../contact';
import Footer from '../footer';


export default function Home() {

  return (
    <>
      <Header>
        <Menu/>
        <HeaderSlider/>
      </Header>
      {/*<About/>*/}
      <Plans/>
      <Service/>
      <Contact/>
      <Footer/>
    </>
  );
}

const Header = styled.header`
  background: ${`url(${background}) no-repeat top right`};
  background-size: 60% 100%;

  @media (max-width: 1224px) {
    background: none;
  }
`;
