import React from 'react'
import styled from 'styled-components'
import LaptopImg from '../../images/laptop-sac.png'

export default function HeaderSlider() {


    return (
        <Container>
            <ContentLeft>
                <Title>Central do Assinante</Title>
                <Description>
                    Tenha acesso completo a todas as informações da sua assinatura. <br/> Imprima segunda via de boletos
                    e muito
                    mais.
                </Description>
                <Button onClick={()=>{
                    const link = "http://gerenet.alogtelecom.net.br/sac2"
                    const win = window.open(link, '_blank');
                    win.focus();
                }}>ACESSAR CENTRAL</Button>
            </ContentLeft>
            <ContentRight>
                <Image src={LaptopImg} alt="Central do assinante"/>
            </ContentRight>
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 64px 0 ;
  
  @media (max-width: 1224px) { 
    flex-direction: column;
    align-content: center;
    padding: 16px 0;
    //background: #e1a83e;
    background: #e1a83e;
    color: #FFF;
  }
 
`

const ContentLeft = styled.div`
  display: block;
 
`

const Title = styled.span`
  display: block;
  color: #0c0c06;
  font-weight: 800;
  font-size: 32px;
  padding-bottom: 16px;
  
  @media (max-width: 1224px) { 
    font-size: 26px;
    text-align: center;
    padding-bottom: 8px;
    color: #FFF;
  }
 
`
const Description = styled.span`
  display: block;
  color: #929292;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 24px;
  line-height: 24px;
   
  @media (max-width: 1224px) { 
    font-size: 16px;
    text-align: left;
    padding: 0 16px 16px 16px;
    color: #FFF;
    word-break: break-word;
    }
 
`
const Button = styled.button`
  display: block;
  background: #e1a83e;
  border-radius: 24px;
  color: #FFF;
  padding: 12px 24px;
  cursor: pointer;
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
  border: 1px solid transparent;
  text-decoration: none;
 
  :hover{
    box-shadow: 0 2px 14px rgba(0,0,0,0.1);
  }
  
  @media (max-width: 1224px) {
    width: calc(100% - 32px);
    background: #FFF;
    color: #CFC038;
    font-weight: 600;
    font-size: 16px;
    margin-left: 16px;
  }
  
`
const ContentRight = styled.div`
  display: block;
 
 
`
const Image = styled.img`
  display: block;
  height: 250px;
  //width: 64px;
  
  @media (max-width: 1224px) {
    display: none;
  }
 
`
