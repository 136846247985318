import React from 'react'
import styled from 'styled-components'
import logo from '../../images/novo_logo.png'
import Icons from '../Icons'

export default function Footer() {

    return (
        <>
            <Container>
                <Left>
                    <Logo src={logo}/>
                    <Label>Confira mais em nossas redes sociais</Label>
                    <Icon>
                        <div onClick={() => window.open('https://www.facebook.com/alogtelecom', '_blank')}>
                            <Icons icon="facebook"/>
                        </div>
                        <div onClick={() => window.open('https://www.instagram.com/alogtelecom', '_blank')}>
                            <Icons icon="instagram"/>
                        </div>
                    </Icon>
                </Left>
                <Right>
                    <Link href={"#servicos"}>Serviços</Link>
                    <Link href={"#planos"}>Planos</Link>
                    <Link href={"#contato"}>Contato</Link>
                    <Link href="https://www.speedtest.net/pt" target={"_blank"}>Speed test</Link>
                </Right>
            </Container>
            <Copiraite>
                @log Telecom - 2022
            </Copiraite>
        </>
    )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
  background: #F5FCFD;
`

const Left = styled.div`
  padding: 16px;
  display: block;
  width: 30%;
  text-align: left;

`
const Logo = styled.img`
  height: 48px;
  display: block;
`

const Label = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #929292;
  margin-top: 16px;

`

const Icon = styled.div`
  padding-top: 16px;
  display: flex;
  
  svg {
    height: 32px;
    margin-right: 16px;
    width: 32px;
    fill: rgba(0,0,0,0.3);
    cursor: pointer;
    
    :hover {
      fill: #e1a83e;
    }
    
  }
`

const Right = styled.div`
  padding: 16px;
`

const Link = styled.a`
  padding: 8px 0;
  color: #0c0c06;
  font-weight: 500;
  display: block;
  cursor: pointer;
  text-decoration: none;
  
  :hover {
    color: #e1a83e;
  }
`

const Copiraite = styled.div`
  border-top: 1px solid rgba(0,0,0, .1);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5FCFD;
  font-size: 12px;
  color: #929292;  
`
