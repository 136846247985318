import React from 'react';
import styled from 'styled-components';

export default function Contact() {

  return (
    <Container>
      <TitleSection>ENTRE EM CONTATO</TitleSection>
      <Description>Ficou com alguma dúvida? Entre em contato conosco agora mesmo.</Description>

      <Content id="contato">
        <Form>
          <Label>Nome:</Label>
          <Imput type="text" placeholder="Seu nome"/>
          <Label>Seu e-mail:</Label>
          <Imput type="email" placeholder="Seu e-mail"/>
          <Label>Seu telefone:</Label>
          <Imput type="tel" placeholder="Seu telefone"/>
          <Label>Sua mensagem:</Label>
          <TextArea placeholder="Sua mensagem"/>
          <FormSubmit>
            <Button>ENVIAR</Button>
          </FormSubmit>
        </Form>
        <ContactData>
          <Title>Endereço</Title>
          <Info>Av. Professor Zeferino 1176, sala 1A<br/>Centro, São João da Urtiga - RS <br/> CEP:
            99855-000</Info>

          <Title>E-mail</Title>
          <Info>atendimento@alogtelecom.com.br</Info>

          <Title>Telefone</Title>

          <Info href="https://wa.me/555435321471" target="_blank">(54) 3532-1471 (WhatsApp)</Info>
          <Info href="tel:54996959694">(54) 9 9695-9694</Info>

        </ContactData>
      </Content>


    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 0;

  @media (max-width: 1224px) {
    padding: 24px 16px;
  }

`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 550px);

  @media (max-width: 1224px) {
    flex-direction: column;
    width: 100%;
  }

`;
const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 100px;

  @media (max-width: 1224px) {
    width: 100%;
    margin-left: 0;
  }
`;

const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #0c0c06;
  padding-top: 32px;
`;

const Info = styled.a`
  font-size: 18px;
  color: #0c0c06;
  cursor: pointer;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  min-width: 40%;
  max-width: 40%;

  @media (max-width: 1224px) {
    width: 100%;
    max-width: 95%;
  }
`;

const TitleSection = styled.span`
  display: block;
  color: #0c0c06;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 8px;


`;

const Description = styled.span`
  display: block;
  color: #929292;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 48px;
  line-height: 24px;

  @media (max-width: 1224px) {
    padding-bottom: 16px;
  }
`;

const Label = styled.span`
  color: #0c0c06;
  font-size: 14px;
  padding: 8px 16px;
  font-weight: 500;
`;

const Imput = styled.input`
  background: #FFF;
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  border: 1px solid #e3e3e3;
  color: #0c0c06;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, .1);
  max-width: 100%;
  width: 100%;
  margin: 0 0 16px 0;
  padding: 0 0 0 16px;

  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 300;
    color: #929292;
    font-size: 12px;
  }

  :-ms-input-placeholder {
    font-weight: 300;
    color: #929292;
    font-size: 12px;
  }


  @media (max-width: 1224px) {

  }
`;

const TextArea = styled.textarea`
  font-family: 'Quicksand', sans-serif;
  background: #FFF;
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  font-size: 1rem;
  height: 80px;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  border: 1px solid #e3e3e3;
  color: #0c0c06;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, .1);
  max-width: 100%;
  width: 100%;
  margin: 0 0 16px 0;
  padding: 0 0 0 16px;

  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 300;
    color: #929292;
    font-size: 12px;
    padding-top: 8px;
  }

  :-ms-input-placeholder {
    font-weight: 300;
    color: #929292;
    font-size: 12px;
    padding-top: 8px;
  }

  @media (max-width: 1224px) {
    margin: 0;
  }
`;


const FormSubmit = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0;

  @media (max-width: 1224px) {
    width: 100%;
  }
`;
const Button = styled.div`
  margin-top: 8px;
  border-radius: 24px;
  background: #e1a83e;
  color: #FFF;
  padding: 12px 32px;
  cursor: pointer;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border: 2px solid #e1a83e;

  :hover {
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
  }
`;
