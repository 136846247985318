import React from 'react'
import styled from 'styled-components'
import ServiceItem from './serviceItem'

const servicesData = [
  {
    icon: 'fiber',
    title: 'INTERNET FIBRA OPTICA E VIA RÁDIO',
    description: 'A melhor qualidade de internet para cidade e interior.'
  },
  {
    icon: 'fiber',
    title: 'SUPRIMENTOS DE INFORMÁTICA',
    description: 'Possuimos em nossa loja, os mais diversos suprimentos de informática.'
  },
  {
    icon: 'fiber',
    title: 'ASSISTÊNCIA TÉCNICA ESPECIALIZADA',
    description: 'Seu dispositivo precisou de conserto? Nós temos uma equipe especializada ao seu dispor.'
  },
  {
    icon: 'fiber',
    title: 'LOCAÇÃO DE IMPRESSORAS',
    description: 'Locação de impressoras monocromática e colorida, entre em contato.'
  }
]

export default function Service() {

  return (
    <Container id="servicos">
      <Title>SERVIÇOS</Title>
      <Description>Oferecemos uma ampla gama de serviços
        para ajudar você. </Description>
      <ServiceItems>
        {servicesData.map(it => <ServiceItem key={it.icon} title={it.title} icon={it.icon}
                                             description={it.description}/>)}
      </ServiceItems>
    </Container>
  )
}

const Container = styled.div`
  background: #F5FCFD;
  padding: 64px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media (max-width: 1224px) {
    padding: 24px 0;
  }
  
`

const Title = styled.span`
  display: block;
  color: #0c0c06;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 8px;
 
 
`

const Description = styled.span`
  display: block;
  color: #929292;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 48px;
  line-height: 24px;
  
  @media (max-width: 1224px) {
    padding: 0 16px 24px;
  }
`

const ServiceItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0 128px;
  
  @media (max-width: 1224px) {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-content: center;
    margin: 0;
    padding: 0;
  }
`
